import React from "react";
import Labels from "./pages/Labels";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import MuseumProjects from "./pages/Projects";
import MuseumImages from "./pages/Images";
import Component404 from "./components/common/ErrorPage";

const router = createBrowserRouter([
  { path: "/", element: <Labels /> },
  { path: "/:label/", element: <MuseumProjects /> },
  { path: "/:label/:project", element: <MuseumImages /> },
  { path: "*", element: <Component404 additionalClassName={"bg-black/90"} /> },
]);

const App = () => {
  // const errorElement = <Component404 />;
  return (
    <div className="App" style={{ position: "relative" }}>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
