import React from "react";

const Footer = ({ copyrightText }: { copyrightText?: string }) => {
  return (
    <>
      <div className="absolute py-1 flex justify-center bottom-0 text-white text-[0.5rem] md:text-[0.7rem] text-center w-screen opacity-75">
        <div className={'break-words max-w-[65dvw]'}>
          {copyrightText ? copyrightText : '©2023 TORICO All Rights Reserved.'}
        </div>
      </div>
    </>
  );
};

export default Footer;
