import "@splidejs/splide/css";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "./Images.css";
import ImageSlider from "../components/sliders/ImageSlider";
import Environment from "../components/meshes/Environment";
import { Canvas } from "@react-three/fiber";
import ErrorComponent from "../components/common/ErrorPage";
import _axios from "../utils/axios";
import Head from "../components/common/Helmet";
import { gaEventSender } from "../utils/gaTracking";

function MuseumImages() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [label, setLabel] = useState<LabelAPI>();
  const [project, setProject] = useState<ProjectAPI>();
  const [images, setImages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const sk = searchParams.get("sk");
  const fetchData = () => {
    return _axios
      .get(`api/museum/${params.label}/${params.project}/images/?sk=${sk}`)
      .then(async (res) => {
        setImages(await res.data.results);
        setIsLoaded(true);
        return res.data.results;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchData().then((r) => {
      if (r.length) {
        Promise.all([fetchLabel(r[0].label), fetchProject(r[0].project)]).then(
          () => {
            gaEventSender({
              action: "page_viewed",
              params: {
                label: label?.slug,
                project: project?.slug,
              },
            });
          }
        );
      }
    });
  }, []);

  const fetchLabel = (labelId: number) => {
    return _axios.get(`api/museum/label/${labelId}/`).then((r) => {
      setLabel(r.data);
    });
  };

  const fetchProject = (projectId: number) => {
    return _axios.get(`api/museum/project/${projectId}/`).then((r) => {
      setProject(r.data);
    });
  };

  const pathName = {
    pathname: `/${label?.slug}/`,
    search: sk ? `?sk=${sk}` : "",
  };

  return (
    <>
      <Head
        title={
          label && project
            ? `${label?.title} | ${project?.title} | Webミュージアム`
            : undefined
        }
      />
      <div className="relative">
        <Canvas className="main-canvas" camera={{ position: [0, 4, 20] }}>
          <Environment />
        </Canvas>

        {images.length ? <ImageSlider data={images} pathName={pathName} /> : ""}
        {isLoaded && !images.length ? (
          <ErrorComponent errorMessage="画像が登録されていません" />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default MuseumImages;
