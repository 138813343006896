import * as THREE from "three";
import React, {useEffect} from "react";

import floor01 from "../../images/floor01.jpg";
import {useFrame, useLoader, useThree} from "@react-three/fiber";
import { TextureLoader } from "three";

const Environment = () => {
  const floor01Texture = useLoader(TextureLoader, floor01);

    const CameraController = () => {
        const { camera, gl, pointer,} = useThree();
        useEffect(() => {
        }, [camera, gl, pointer]);
        return null;
    };

    const Clock = (()=> {
        useFrame(({viewport,clock, camera, pointer, controls})=>{
            // カメラ視点ポインタ追従機能　一旦コメントアウト
            // const slope = 0.005
            // camera.lookAt(new THREE.Vector3(
            //     pointer.x * viewport.width * slop,
            //     pointer.y * viewport.height* slop,
            //     0))
        })
        return null
    })

  return (
    <>
        <CameraController />
        <Clock />
        <ambientLight args={[0xffffff]} intensity={0.5} />
        <pointLight args={[0xffffff]} intensity={1} position={[0, 5, 1]}/>
        <spotLight
            args={[0xffffff]} intensity={1} position={[-3, 5, 3]} angle={Math.PI/2}
        />
        <directionalLight color="white" position={[2, 1, 5]} />
      {/*壁 奥*/}
      <mesh position={[0, 0, -20]} >
        <planeGeometry args={[50, 50]} />
        <meshStandardMaterial
          color={0x000000}
          transparent={false}
          opacity={1}
          alphaTest={0.5}
        />
      </mesh>

      {/*壁 左*/}
      <mesh
        position={[-15, 0, -10]}
        rotation={new THREE.Euler(Math.PI / 2, Math.PI / 2, 0)}
      >
        <planeGeometry args={[50, 50]} />
        <meshStandardMaterial
          color={0x000000}
          transparent={false}
          opacity={1}
          alphaTest={0.5}
        />
      </mesh>

      {/*壁 右*/}
      <mesh
        position={[15, 0, -10]}
        rotation={new THREE.Euler(Math.PI / 2, Math.PI / 2, 0)}
      >
        <planeGeometry args={[50, 50]} />
        <meshStandardMaterial
          color={0x000000}
          transparent={false}
          opacity={1}
          alphaTest={0.5}
          side={THREE.DoubleSide}
        />
      </mesh>

      {/*壁 底*/}
      <mesh
        position={[0, -20, 0]}
        rotation={new THREE.Euler(Math.PI / 2, 0, Math.PI)}
      >
        <planeGeometry args={[50, 50]} />
        <meshStandardMaterial
          map={floor01Texture}
          transparent={false}
          opacity={1}
          alphaTest={0.5}
          side={THREE.DoubleSide}
        />
      </mesh>

      {/*壁 天井*/}
      <mesh
        position={[0, 20, 0]}
        rotation={new THREE.Euler(Math.PI / 2, 0, Math.PI / 2)}
      >
        <planeGeometry args={[50, 50]} />
        <meshStandardMaterial
          color={0x000000}
          opacity={1}
          alphaTest={0.5}
          side={THREE.DoubleSide}
        />
      </mesh>
    </>
  );
};

export default Environment;
