import React, {
  RefCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import ProjectContext from "../store/Projects/project-context";
import { useSwipeable } from "react-swipeable";

const ProjectMesh = React.forwardRef(
  (
    props: {
      position: [number, number, number];
      rotation: [number, number, number];
      texture: TextureModel;
      projectDetail: any;
      index: number;
      allowScroll: { up: boolean; down: boolean };
      updateAllowScroll: any;
      length: number;
      isLoaded: boolean;
      isMobile: boolean;
    },
    _ref
  ) => {
    const imgSize = { x: 7, y: 7 }; // 画像の大きさは正方形の場合で 最大 7×7
    const edgeOffset = 0.5;

    const scrollTime = 500;
    const initLastMeshPositionY = 1.5; // 最後尾のプロジェクトの初期Y座標
    const adjustPositionValue = 0.05;

    // ProjectMesh が移動できるY座標の範囲
    const visibleAreaYMax = props.isMobile ? 1.5 : 5.5;
    const visibleAreaYMin = props.isMobile ? 1.5 : -2.0;

    // 全体の動きは、最後尾のオブジェクトの座標で管理する
    const isLast = props.length - 1 === props.index;

    const [position, setPosition] = useState([0, 0, 0]);
    const [edgePosition, setEdgePosition] = useState([0, 0, 0]);
    const [rotation, setRotation] = useState([0, 0, 0]);
    const [edgeRotation, setEdgeRotation] = useState([0, 0, 0]);
    const [onMove, setOnMove] = useState(false);
    const [onMoveTime, setOnMoveTime] = useState(0);
    const [allowScroll, setAllowScroll] = useState({ up: true, down: true });

    const [moveAmount, setMoveAmount] = useState(0);
    const [isRendered, setIsRendered] = useState(false);
    const [initPosition, setInitPosition] = useState(false);
    const [touchStartY, setTouchStartY] = useState<number | null>(null);
    const [touchEndY, setTouchEndY] = useState<number | null>(null);
    const [destPositionY, setDestPositionY] = useState(1.5); // Y軸の目標座標
    const [strictMove, setStrictMove] = useState(false); //  プロジェクト単位で移動させるモード
    const [moveDirection, setMoveDirection] = useState(true); // strictMove で使う

    const {
      lastMeshPositionY,
      setLastMeshPositionY,
      isVisibleModal,
      setIsVisibleModal,
      currentProjectIndex,
      setSelectedProjectIndex,
      setIsInit
    } = useContext(ProjectContext);

    useEffect(() => {
      setAllowScroll(props.allowScroll);
    }, [props.allowScroll]);

    useEffect(() => {
      setIsRendered(true);
    }, []);

    useEffect(() => {
      setIsInit(initPosition);
    }, [initPosition]);

    // easings.net  https://easings.net/ja#easeOutCirc
    const easeOutCirc = (x: number): number => {
      return Math.sqrt(1 - Math.pow(x - 1, 2));
    };

    const easeOutSine = (x: number): number => {
      return Math.sin((x * Math.PI) / 2);
    };

    function easeInOutBack(x: number): number {
      const c1 = 1.70158;
      const c2 = c1 * 1.525;

      return x < 0.5
        ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
        : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
    }

    useMemo(() => {
      // 一番最初がいい位置にくるまでスクロールする
      window.addEventListener(
        "wheel",
        (e: any) => {
          // up -, down +
          if (!onMove) {
            let slope = 0.01 * Math.min(Math.abs((e.deltaY / 100) * 5), 10);
            const amount = e.deltaY < 0 ? slope : -slope;

            setOnMove(true);
            setOnMoveTime(e.timeStamp);
            setMoveAmount(amount);
            setTimeout(() => {
              setOnMove(false);
              setOnMoveTime(0);
            }, scrollTime);
          }
        },
        { passive: true }
      );

      window.addEventListener("touchstart", (e: any) => {
        setTouchStartY(e.changedTouches[0].screenY);
      });

      window.addEventListener("touchend", (e: any) => {
        setTouchEndY(e.changedTouches[0].screenY);
      });

      window.addEventListener("touchmove", (e: any) => {
        // up -, down +
        if (!onMove && touchStartY !== null && touchEndY !== null) {
          const positionY = touchStartY - touchEndY;
          const slop = 0.01 * Math.min(Math.abs((positionY / 100) * 5), 10);
          const amount = positionY < 0 ? slop : -slop;

          setOnMove(true);
          setMoveAmount(amount);
          setTimeout(() => {
            setOnMove(false);
          }, scrollTime);
        }
      });
    }, []);

    window.addEventListener("keydown", (e: any) => {
      if (e.code == "ArrowRight" || e.code == "ArrowUp") {
          moveStrictly(true);
      }
      if (e.code == "ArrowLeft" || e.code == "ArrowDown") {
        moveStrictly(false);
      }
    });

    // プロジェクト位置を初期化
    useMemo(() => {
      if (props.position) {
        setPosition(props.position);
        const edge = [...props.position];
        edge[2] = edge[2] - 0.01;
        setEdgePosition(edge);
      }
      if (props.rotation) {
        setRotation(props.rotation);
        const edge = [...props.rotation];
        edge[2] = edge[2] - 0.01;
        setEdgeRotation(edge);
      }
    }, []);

    // タッチ操作制御
    const { ref } = useSwipeable({
      onSwipedRight: () => {
        if (!isVisibleModal) {
          moveStrictly(false);
        }
      },
      onSwipedLeft: () => {
        if (!isVisibleModal) {
          moveStrictly(true);
        }
      },
      onTap: () => {
        if (!initPosition) {
          setInitPosition(true);
          setOnMove(false);
        }
      },
    }) as { ref: RefCallback<Document> };

    useEffect(() => {
      ref(document);
      return () => ref(document);
    });

    useEffect(() => {
      if (!onMove && touchStartY !== null && touchEndY !== null) {
        const positionY = touchStartY - touchEndY;
        const slop = 0.01 * Math.min(Math.abs((positionY / 100) * 10), 10);
        const amount = positionY < 0 ? -slop : slop;

        // debugger
        setOnMove(true);
        setMoveAmount(amount);
        setTimeout(() => {
          setOnMove(false);
        }, scrollTime);
      }
    }, [touchEndY]);

    const projectMeshShaderMaterial = useRef();

    useEffect(() => {
      if (initPosition) {
        const canGoUp = !(isLast && position[1] > visibleAreaYMax);
        const canGoDown = !(isLast && position[1] < visibleAreaYMin - (props.length - 1));
        props.updateAllowScroll({ up: canGoUp, down: canGoDown });
      }
      if (props.length - 1 === props.index) {
        setLastMeshPositionY(position[1]);
      }
    }, [position[1]]);

    useFrame(() => {
      if (!props.isLoaded) {
        // load 前は何もしない
      } else {
        if (!initPosition) {
          setOnMove(true);
          setMoveAmount(0.05);
        }
        if (
          !initPosition &&
          lastMeshPositionY > initLastMeshPositionY - adjustPositionValue
        ) {
          setInitPosition(true);
          setOnMove(false);
        }

        if (strictMove) {
          const amount = 0.05;
          // 現在座標 - 目的座標で方向を決める
          const diff = lastMeshPositionY - destPositionY;

          // dir が -の場合
          if (moveDirection) {
            if (diff > -adjustPositionValue) {
              setOnMove(false);
              setStrictMove(false);
            }
          } else {
            if (diff < adjustPositionValue) {
              setOnMove(false);
              setStrictMove(false);
            }
          }
          const dir = moveDirection ? 1 : -1;
          setMoveAmount(amount * dir);
        }

        if (onMove && (allowScroll.up || allowScroll.down)) {
          const amount =
            (moveAmount > 0 && !allowScroll.up) ||
            (moveAmount < 0 && !allowScroll.down)
              ? 0
              : moveAmount;

          const slop = 6;
          const pos = [...position];
          const edgePos = [...edgePosition];
          const rot = [...rotation];
          const edgeRot = [...edgeRotation];
          pos[1] += amount;
          edgePos[1] += amount;

          const adjustSlop = 1.35;
          pos[0] = Math.cos(pos[1]) * slop * adjustSlop;
          edgePos[0] = Math.cos(edgePos[1]) * slop * adjustSlop;
          pos[2] = Math.sin(pos[1]) * slop;
          edgePos[2] = Math.sin(edgePos[1]) * slop - 0.1;
          rot[1] = Math.abs(Math.sin(pos[1]) - THREE.MathUtils.degToRad(60));
          edgeRot[1] = Math.abs(
            Math.sin(edgePos[1]) - THREE.MathUtils.degToRad(60)
          );
          setPosition(pos);
          setEdgePosition(edgePos);
          setRotation(rot);
          setEdgeRotation(edgeRot);
        }
      }
    });

    const getIsVisible = (): boolean => {
      return isRendered && position[1] >= -2.5 && position[1] <= 6;
    };

    const moveStrictly = (direction: boolean) => {
      // + true(左にずれる) ● ← ● ← ●
      // - false(右にずれる) ● → ● → ●

      // LastMeshPositionY の取るべき最大値(最後尾の ProjectMesh がセンターポジションにある状態)
      const max = initLastMeshPositionY + adjustPositionValue;

      // LastMeshPositionY の取るべき最小値(最前の ProjectMesh がセンターポジションにある状態)
      const min =
        initLastMeshPositionY - props.length + 1 - adjustPositionValue;
      //  Yの少数第一位が5の場合に中央にくる。0.5丁度だと少し手前で止まるので多めに
      const centerPositionY = direction ? 0.52 : 0.57
      let dest;
      if (direction) {
        if (lastMeshPositionY < min) {
          // 最前列をセンターポジションにする
          setDestPositionY(min);
        } else {
          // 1プロジェクト分移動させる
          dest = Math.floor(lastMeshPositionY + 1) + centerPositionY;
          setDestPositionY(dest);
        }
      } else {
        if (lastMeshPositionY > max + 0.1) {
          // 最後尾をセンターポジションにする
          setDestPositionY(max);
        } else {
          dest = Math.floor(lastMeshPositionY - 1) + centerPositionY;
          setDestPositionY(dest);
        }
      }
      setOnMove(true);
      setStrictMove(true);
      setMoveDirection(direction);
    };

    const clicked = () => {
      if (position[2] > 1 && getIsVisible()) {
        setIsVisibleModal(true);
        setSelectedProjectIndex(props.index);
        props.projectDetail.current.classList.add("visible");
      }
    };

    return (
      <>
        <mesh
          position={position as any}
          rotation={rotation as any}
          visible={getIsVisible()}
          ref={_ref as any}
          onClick={() => {
            clicked();
          }}
          onPointerOver={() => {
            if (position[2] > 1) {
              document.body.style.cursor = "pointer";
            }
          }}
          onPointerOut={() => {
            document.body.style.cursor = "default";
          }}
        >
          <planeGeometry
            args={[
              imgSize.x * props.texture.ratioX,
              imgSize.y * props.texture.ratioY,
            ]}
          />
          <shaderMaterial
            ref={projectMeshShaderMaterial as any}
            vertexShader={vertexDefinition}
            fragmentShader={fragmentDefinition}
            side={THREE.DoubleSide}
            needsUpdate={true}
            uniforms={{
              uFrontImg: { value: props.texture.textureImg },
            }}
          />
        </mesh>

        {/*Edge*/}
        <mesh
          position={edgePosition as any}
          rotation={edgeRotation as any}
          visible={getIsVisible()}
        >
          <planeGeometry
            args={[
              imgSize.x * props.texture.ratioX + edgeOffset,
              imgSize.y * props.texture.ratioY + edgeOffset,
            ]}
          />
          <meshStandardMaterial
            color={new THREE.Color("#222")}
            side={THREE.DoubleSide}
          />
        </mesh>
      </>
    );
  }
);

export default ProjectMesh;

const vertexDefinition = `
varying vec2 v_uv;

void main() {
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;

    gl_Position = projectionPosition;
    v_uv = uv;
}
	`;

const fragmentDefinition = `
uniform sampler2D uFrontImg;
varying vec2 v_uv;

void main() {
    vec4 texel = texture2D( uFrontImg, v_uv );
    gl_FragColor = vec4(texel.a < 0.5 ? vec3(1.0) : texel.rgb, 0.8);
}
	`;
