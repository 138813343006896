import React, {
  memo,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM, { createPortal } from "react-dom";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import "./Projects.css";
import Environment from "../components/meshes/Environment";
import ProjectMesh from "../components/meshes/ProjectMesh";
import ProductListSlider from "../components/sliders/ProductListSlider";
import ProjectContext, {
  ModalContextProvider,
} from "../components/store/Projects/project-context";
import { loadTextures } from "../utils/textureLoader";
import Component404 from "../components/common/ErrorPage";
import _axios from "../utils/axios";
import Head from "../components/common/Helmet";
import Footer from "../components/common/Footer";
import parse from "html-react-parser";
import { gaEventSender } from "../utils/gaTracking";
import ProjectCarousel from "../components/sliders/ProjectCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons/faXTwitter";
import { faSquareXTwitter } from "@fortawesome/free-brands-svg-icons/faSquareXTwitter";

const MuseumProjects = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [label, setLabel] = useState<LabelAPI>();
  const [projects, setProjects] = useState<ProjectAPI[]>();
  const [textures, setTextures] = useState<TextureModel[]>();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [textureLength, setTextureLength] = useState(0);
  const [eventProductList, setEventProductList] = useState<ProductModelData[]>(
    []
  );
  const [use3D, setUse3D] = useState(true);
  const [copyrightText, setCopyrightText] = useState("");

  useEffect(() => {
    setTextureLength(Number(textures?.length));
  }, [projects]);

  const sk = searchParams.get("sk");

  const isMobile = navigator.userAgent.match(/iPhone|Android.+Mobile/);

  // カメラ座標(x, y, z)
  const cameraPosition: [number, number, number] = isMobile
    ? [0, 1, 18]
    : [0, 2, 20];

  const fetchData = () => {
    return _axios
      .get(`api/museum/${params.label}/projects/?sk=${sk}`)
      .then(async (res) => {
        setProjects(await res.data.results);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchEventProducts = (eventId: number) => {
    return _axios.get(`api/event/eventproduct/${eventId}/`).then((r) => {
      setEventProductList(r.data.paginator.current_page.object_list);
    });
  };

  const fetchLabel = (labelId: number) => {
    return _axios.get(`api/museum/label/${labelId}/`).then((r) => {
      setLabel(r.data);
      setUse3D(r.data.use_3d_project_list);
      setCopyrightText(r.data.copyright);
    });
  };

  useEffect(() => {
    if (!isDataLoaded) {
      fetchData().then((r) => {
        if (!r?.data.results.length) {
          setIsDataLoaded(true);
        } else {
          Promise.all([
            loadTextures(r?.data.results).then((r) => setTextures(r)),
            r?.data.results[0].related_event
              ? fetchEventProducts(r.data.results[0].related_event)
              : null,
            r?.data ? fetchLabel(r.data.results[0].label) : null,
          ]).then((r) => {
            setIsDataLoaded(true);
            gaEventSender({
              action: "page_viewed",
              params: {
                label: label?.slug,
              },
            });
          });
        }
      });
    }
  }, []);

  const Clock = () => {
    useFrame(({ clock }) => {
      if (clock.elapsedTime < 2) {
        // 初期レンダリングを確実に行うために必要
        setTextureLength(Number(textures?.length));
      }
    });
    return <></>;
  };

  const projectMesh = useRef<RefObject<any>[]>([]);
  projects?.forEach((_, index) => {
    projectMesh.current[index] = React.createRef();
  });

  const projectPage = useRef<HTMLDivElement>(null);
  const productList = useRef<HTMLDivElement>(null);
  const projectDetail = useRef<HTMLDivElement>(null);

  const ProjectMeshes = () => {
    const [allowScroll, setAllowScroll] = useState({ up: true, down: true });

    const updateAllowScroll = (as: { up: boolean; down: boolean }) => {
      setAllowScroll(as);
    };
    const { isVisibleModal } = useContext(ProjectContext);

    useEffect(() => {
      setAllowScroll({ up: !isVisibleModal, down: !isVisibleModal });
    }, [isVisibleModal]);

    if (isDataLoaded && textures?.length) {
      const texes = textures.sort((a, b) => a.index - b.index);
      return (
        <>
          {texes.map((t, index) => (
            <ProjectMesh
              ref={projectMesh.current[index]}
              allowScroll={allowScroll}
              key={index}
              projectDetail={projectDetail}
              position={[0, -3 - index, 0]}
              rotation={[0, THREE.MathUtils.degToRad(index * 30), 0]}
              texture={t}
              index={index}
              updateAllowScroll={updateAllowScroll}
              length={textures.length}
              isLoaded={isDataLoaded}
              isMobile={Boolean(isMobile)}
            />
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  const MemoMesh = memo(ProjectMeshes);

  /**
   * センターポジションに位置しているプロジェクトのタイトル
   * **/
  const ProjectTitle = () => {
    const {
      lastMeshPositionY,
      isVisibleModal,
      currentProjectIndex,
      setCurrentProjectIndex,
    } = useContext(ProjectContext);
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");

    useEffect(() => {
      if (projects) {
        const adjuster = 0.5;
        const centerPosition = 1.5;
        let current = Math.floor(
          lastMeshPositionY + projects.length - 1 - centerPosition + adjuster
        );
        if (currentProjectIndex !== current) {
          setCurrentProjectIndex(current);
        }
        if(!use3D){
          setCurrentProjectIndex(0);
        }
      }
    }, [lastMeshPositionY]);

    const titleRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (projects && titleRef.current) {
        titleRef.current.style.display = "none"; // アニメーションの為、一度消す
        setTimeout(() => {
          if (titleRef.current) {
            titleRef.current.style.display = "block";
          }
        }, 100);

        if (
          currentProjectIndex >= 0 &&
          currentProjectIndex <= projects.length - 1
        ) {
          setTitle(projects[currentProjectIndex].title);
          setSubTitle(projects[currentProjectIndex].subtitle);
        } else {
          setTitle("");
          setSubTitle("");
        }
      }
    }, [currentProjectIndex]);

    return (
      <>
        {isDataLoaded ? (
          <div className="w-screen flex justify-center text-center text-white absolute bottom-[25%]">
            <div
              ref={titleRef}
              className={`title-animation relative min-h-[50px] max-w-[90%] lg:max-w-[50%] ${
                isVisibleModal ? "invisible" : "z-20"
              }`}
            >
              {subTitle ? <h6 className="text-[0.9rem]"> {subTitle} </h6> : ""}
              <h5 className="text-[1.15rem] font-bold"> {title} </h5>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  /**
   * 商品リストを表示するトリガーボタン
   * **/
  const ProductListModalTrigger = () => {
    const { isVisibleModal, setIsVisibleModal } = useContext(ProjectContext);

    const circleSize = isMobile ? 50 : 70;

    const notifier = useRef<HTMLDivElement>(null);

    const Notifier = () => {
      return (
        <div
          ref={notifier}
          className="w-[190px] h-[55px] lg:w-[270px] lg:h-[100px] bg-white border-black border-2 lg:border-4 rounded-3xl border-solid absolute z-30 right-[35px] bottom-[35px] lg:right-[75px] lg:bottom-[75px] notice-modal-animation cursor-pointer"
          onClick={() => {
            setIsVisibleModal(true);
            productList?.current?.classList.add("visible");
            gaEventSender({
              action: "view_product_list",
              params: {
                label: label?.slug,
              },
            });
          }}
        >
          <div className="grid content-center justify-center h-full relative text-sm lg:text-[0.9rem]">
            <span>
              <i className="fa-solid fa-slash hidden lg:inline-block"></i>
              <span className="font-bold">関連グッズはコチラ！</span>
              <i className="fa-solid fa-slash fa-flip-horizontal hidden lg:inline-block"></i>
            </span>
            {!isMobile ? (
              <span className="font-bold text-[1rem] lg:text-2xl">
                Click Here
              </span>
            ) : (
              ""
            )}
            <i className="text-3xl lg:text-5xl text-[#da6b6b] fa-solid fa-hand-pointer fa-beat absolute -right-1 -bottom-2.5 lg:-right-5 lg:-bottom-5"></i>
          </div>
        </div>
      );
    };

    const notifierMemo = useMemo(() => <Notifier />, []);

    if (eventProductList?.length) {
      return (
        <>
          {!isVisibleModal ? notifierMemo : ""}
          <div className="z-[1] absolute right-0 bottom-0 p-3 md:p-10 cursor-pointer">
            <div
              className="text-circle font-bold m-[10px] text-[8px] w-[50px] h-[50px] md:text-[9px] md:w-[70px] md:h-[70px]"
              onClick={() => {
                setIsVisibleModal(true);
                productList?.current?.classList.add("visible");
              }}
            >
              <svg viewBox={`0 0 ${circleSize} ${circleSize}`}>
                <path
                  d={`M 0,${circleSize / 2} a ${circleSize / 2}, ${
                    circleSize / 2
                  } 0 1,1 0,1 z`}
                  id="circle"
                  opacity="1"
                />
                <text>
                  <textPath xlinkHref="#circle" style={{ color: "white" }}>
                    * MANGA10 * RELATED GOODS * CHECK THEM OUT
                  </textPath>
                </text>
              </svg>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  /**
   * 関連商品リスト
   * **/
  const ProductList = () => {
    const { setIsVisibleModal } = useContext(ProjectContext);

    if (eventProductList.length) {
      return ReactDOM.createPortal(
        <>
          <div
            ref={productList}
            className="hidden absolute z-10 top-0 w-full bg-black/80 backdrop-blur-sm"
            onClick={() => {
              setIsVisibleModal(false);
              productList?.current?.classList.remove("visible");
            }}
          >
            {eventProductList?.length ? (
              <ProductListSlider products={eventProductList} />
            ) : (
              ""
            )}
            <button
              className="absolute right-3 bottom-3 lg:top-5 lg:right-[15dvw] bg-gray-900 rounded-full w-[40px] h-[40px]"
              onClick={() => {
                setIsVisibleModal(false);
                productList?.current?.classList.remove("visible");
              }}
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </button>
          </div>
        </>,
        document.getElementsByClassName("museum-projects")[0]
      );
    } else {
      return <></>;
    }
  };

  /**
   * 操作案内の？マーク
   * **/
  const Instruction = () => {
    const { isVisibleModal } = useContext(ProjectContext);

    const [isVisibleInstruction, setIsVisibleInstruction] = useState(false);

    const bgCss =
      "bg-gradient-to-r from-gray-800/50 to-gray-600/50 lg:to-gray-500/50 bg-transparent backdrop-blur-md";
    const InstructionModal = () => {
      return createPortal(
        <>
          <div
            className={`${bgCss} max-w-[90dvw] lg:max-w-sm rounded modal-fade-in text-white overflow-hidden shadow-xl absolute top-5 right-5 z-20 ${
              !isVisibleInstruction ? "" : ""
            }`}
          >
            <div className="px-6 py-4 relative">
              <div className="font-bold text-xl mb-2">
                <i
                  className={`fa-solid fa-${
                    isMobile ? "mobile-screen" : "desktop"
                  } pr-2 pb-2 text-sm leading-[1.75rem]`}
                ></i>
                操作方法
              </div>
              <ul className="text-white text-sm text-left list-disc pl-3">
                <li>
                  {isMobile ? "左右フリック" : "マウスホイール、矢印キー"}
                  で作品を切り替えられます
                </li>
                <li>
                  作品を{isMobile ? "タップ" : "クリック"}すると詳細が表示され、
                  <span className="font-semibold">入場</span>
                  ボタンから公開画像をご覧いただくことができます
                </li>
              </ul>
            </div>
            <span
              className="absolute top-4 right-2 inline-block bg-gray-100 rounded-full leading-[25px] w-[25px] h-[25px] text-[12px] font-semibold text-gray-700 mr-2 mb-2 cursor-pointer"
              onClick={() => {
                setIsVisibleInstruction(false);
              }}
            >
              <i className="fa-solid fa-xmark" />
            </span>
          </div>
        </>,
        document.getElementsByClassName("museum-projects")[0]
      );
    };

    const instructionMemo = useMemo(() => <InstructionModal />, []);

    return (
      <>
        {isDataLoaded && projects?.length && !isVisibleModal ? (
          <>
            <i
              className={`pl-5 pb-5 absolute top-5 right-5 lg:top-10 lg:right-10 text-white fa-regular fa-circle-question z-20 cursor-pointer ${
                isVisibleModal ? "hidden" : ""
              } ${isVisibleInstruction ? "hidden" : ""}`}
              onClick={() => {
                setIsVisibleInstruction(true);
              }}
            ></i>
            {isVisibleInstruction ? instructionMemo : ""}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  /**
   * 詳細画面
   * **/
  const ProjectDetail = () => {
    const { setIsVisibleModal, selectedProjectIndex } =
      useContext(ProjectContext);

    const closeDetailModal = () => {
      setIsVisibleModal(false);
      projectDetail?.current?.classList.remove("visible");
    };

    if (!isDataLoaded) {
      return (
        <div className="absolute z-30 h-screen w-screen grid justify-center content-center">
          <div className="animate-ping h-5 w-5 bg-gray-600 rounded-full"></div>
        </div>
      );
    } else if (isDataLoaded && projects?.length) {
      const project = projects[selectedProjectIndex];

      const pathName = {
        pathname: `${projects[selectedProjectIndex].slug}`,
        search: sk ? `?sk=${sk}` : "",
      };

      const baseTags = [
        "Webミュージアム",
        label?.title,
        projects[selectedProjectIndex].title,
      ];
      const additionalTags =
        projects[selectedProjectIndex].additional_hash_tags.split(",");
      let hashTags = baseTags.concat(additionalTags).join(",");
      if (hashTags.charAt(hashTags.length - 1) === ",") {
        hashTags = hashTags.slice(0, -1);
      }

      return createPortal(
        <div
          ref={projectDetail}
          className={`hidden top-0 absolute bg-black/80 backdrop-blur-sm text-white z-10 h-screen w-screen`}
        >
          <div className="grid justify-center max-h-screen h-full w-full">
            <div className="grid lg:grid-cols-2 content-center lg:mx-3 h-full">
              <Link to={pathName} target="_blank" onClick={closeDetailModal}>
                <img
                  className="mx-auto bg-white max-h-[48dvh] max-w-[90dvw] lg:max-h-[90dvh] lg:max-w-[50dvw] lg:min-h-[500px] object-contain"
                  src={projects[selectedProjectIndex].image_url}
                  alt={projects[selectedProjectIndex].title}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </Link>
              <div className="grid mt-1 lg:mt-0 lg:content-center h-[48dvh] lg:h-full max-h-[48dvh] lg:max-h-full relative overflow-y-auto">
                <div className="grid content-center py-1 lg:py-3">
                  <span className="block my-1">{project.subtitle}</span>
                  <span className="block my-1 lg:my-2 text-2xl">
                    {project.title}
                  </span>
                </div>

                {!project.description ? (
                  ""
                ) : (
                  <div className="my-2 scroll-box w-full min-w-[40vw]">
                    <div>{parse(project.description)}</div>
                  </div>
                )}

                <Link to={pathName} target="_blank" onClick={closeDetailModal}>
                  <button className="border-white bg-black bg-opacity-30 border mt-0 lg:mt-3 w-[100px] h-[50px] active:bg-white hover:bg-white font-semibold active:text-black hover:text-black transition">
                    入場
                  </button>
                </Link>
                <a
                  href={`https://twitter.com/intent/tweet?hashtags=${hashTags}&url=${window.location.href}`}
                  className="hidden lg:block"
                  target="_blank"
                >
                  <button className="w-full text-center mt-3 lg:mt-5 my-2">
                    # <FontAwesomeIcon icon={faXTwitter} className={'ml-2'} /> (旧Twitter) でシェア
                  </button>
                </a>
              </div>
            </div>
          </div>
          <button
            className="absolute right-5 bottom-5 lg:top-[10vh] lg:right-[10vh] bg-gray-900 rounded-full w-[40px] h-[40px]"
            onClick={closeDetailModal}
          >
            <i className="fa-solid fa-xmark text-white"></i>
          </button>
          <a
            href={`https://twitter.com/intent/tweet?hashtags=${hashTags}&url=${window.location.href}`}
            target="_blank"
          >
            <FontAwesomeIcon icon={faSquareXTwitter} className="block lg:hidden text-white text-3xl absolute bottom-[1.5rem] left-[1.5rem]"/>
          </a>
        </div>,
        document.getElementsByClassName("museum-projects")[0]
      );
    } else {
      return <Component404 />;
    }
  };

  const ProjectList = () => {
    if (use3D && textures && textures.length) {
      return <MemoMesh />;
    }
    return <></>;
  };

  return (
    <ModalContextProvider>
      <Head title={label ? `${label.title} | Webミュージアム` : undefined} />
      <div className="museum-projects" ref={projectPage}>
        <ProductListModalTrigger />
        <ProductList />
        <ProjectDetail />
        <ProjectTitle />
        <Instruction />
        {!use3D && isDataLoaded ? (
          <ProjectCarousel projects={projects} ref={projectDetail} />
        ) : (
          ""
        )}

        <Canvas className="main-canvas" camera={{ position: cameraPosition }}>
          <Clock />
          <Environment />
          <ProjectList />
        </Canvas>
      </div>
      {isDataLoaded ? <Footer copyrightText={copyrightText} /> : ""}
    </ModalContextProvider>
  );
};

export default MuseumProjects;
