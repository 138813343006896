import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./ProjectCarousel.css";
import ProjectContext, {
  ModalContextProvider,
} from "../store/Projects/project-context";
import {
  EventHandler,
  ForwardedRef,
  forwardRef,
  Fragment,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
} from "react";

const ProjectCarousel = forwardRef(
  (
    props: {
      projects?: ProjectAPI[];
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const {
      setSelectedProjectIndex,
      setIsVisibleModal,
      setCurrentProjectIndex,
    } = useContext(ProjectContext);
    const projectDetailModal: any = ref;
    const projectCarousel: any = useRef();

    useEffect(() => {
      projectCarousel.current?.splide.on("active", () => {
        setCurrentProjectIndex(projectCarousel.current.splide.index);
      });
    }, [projectCarousel.current]);

    const clickHandler = (project: ProjectAPI)=>{
      // 選択されたプロジェクトの詳細モーダルを表示する
      setSelectedProjectIndex(project._order);
      setIsVisibleModal(true);
      projectDetailModal?.current?.classList.add("visible");
    }

    if (!props.projects?.length) {
      return <></>;
    }
    return (
      <div
        className={
          "project-carousel initialized absolute top-0 z-10 text-white flex justify-center items-center h-full"
        }
      >
        <Splide
          ref={projectCarousel}
          aria-label="ProjectCarousel"
          options={{
            rewind: true,
            perPage: 3,
            focus: "center",
            trimSpace: false,
            arrows: false,
            wheel: true,
            pagination: false,
            keyboard: "global",
            type: "loop",
            breakpoints: {
              800: {
                perPage: 1,
              },
            },
          }}
        >
          {props.projects?.map((p, idx) => {
            return (
              <Fragment key={idx}>
                <SplideSlide>
                  <div
                    className={
                      "flex justify-center items-center h-[50dvh] pt-[28dvh]"
                    }
                  >
                    <div className={"slides cursor-pointer"}>
                      <div className={"project-frame p-2"}>
                        <img
                          className={
                            "max-h-[42dvh] max-w-[400px] object-center"
                          }
                          src={p.image_url}
                          alt={p.title}
                          onClick={()=>{clickHandler(p)}}
                        />
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              </Fragment>
            );
          })}
        </Splide>
      </div>
    );
  }
);
export default ProjectCarousel;
