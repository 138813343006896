import Slides from "./Slides";
import React, {useRef, MutableRefObject} from "react";
import {Splide} from "@splidejs/react-splide";


const ProductListSlider = (props: {
    products: ProductModelData[]
}) => {

    const slider = useRef<MutableRefObject<Splide>>()


    return (<>
        <Splide
            ref={slider as any}
            aria-label="product-list"
            options={{
                direction: "ttb",
                perPage: 3,
                heightRatio: 1,
                height: "100%",
                pagination: false,
                arrows: false,
                rewind: true,
                start: 0,
                wheel: true,
                paginationKeyboard: true,
                lazyLoad: true,
                autoplay: true, // 自動再生を有効
                interval: 2000, // 自動再生の間隔を3秒に設定
            }}
        >
            <Slides contents={props.products} isProduct={true} />
        </Splide>
    </>)
}
export default ProductListSlider