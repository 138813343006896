import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GaContextProvider } from "./components/store/ga-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <GaContextProvider>
        <App />
    </GaContextProvider>
);

reportWebVitals();
