import { Splide } from "@splidejs/react-splide";
import React, { useEffect, useRef, useState } from "react";
import Slides from "./Slides";

const ImageSlider = (props: {
  data: ImageAPI[];
  pathName: { pathname: string; search: string }; // 「作品一覧へ戻る」リンク用
}) => {
  const [currentImage, setCurrentImage] = useState({
    title: "",
    subtitle: "",
    description: "",
    _order: 0,
  });
  const [isInfoVisible, setIsInfoVisible] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0)
  const [canChange, setCanChange] = useState(true);

  const main: any = useRef();
  const thumbnail: any = useRef();
  const imageInfo = useRef<HTMLDivElement>(null);
  const backButton = useRef<HTMLDivElement>(null);

  const getCurrentImageData = () => {
    main.current.splide.on("active", () => {
      setCurrentIndex(main.current.splide.index);
      setCurrentImage(() => {
        return props.data.filter(
          (image, idx) => idx === currentIndex
        )[0];
      });
    });
  };

  const toggleVisibility = () => {
    if (canChange) setCanChange(false);
    setIsInfoVisible(!isInfoVisible);
    setTimeout(() => {
      setCanChange(true);
    }, 300);
  };

  const eventHandler = () => {
    main.current.splide.on("click", () => {
      toggleVisibility();
    });
    main.current.splide.on("ready", () => {});
  };

  useEffect(() => {
    main.current.splide.sync(thumbnail.current.splide);
    if (props.data.length) {
      setCurrentImage(props.data[0]);
    }
  }, []);

  if (main.current) {
    getCurrentImageData();
    eventHandler();
  }

  return (
    <>
      <div className="absolute top-0 right-0 z-20">
        <div
          className="text-white p-5 bg-black/70"
          ref={imageInfo}
          style={{ visibility: isInfoVisible ? "visible" : "hidden" }}
        >
          <p className="">{currentImage.title}</p>
          <p>{currentImage.subtitle}</p>
          <p>{currentImage.description}</p>
          <p>{`${currentIndex + 1} / ${props.data.length}`}</p>
        </div>
      </div>

      <div
        className="absolute top-5 left-5 lg:top-10 lg:left-10 text-white z-20"
        style={{ visibility: isInfoVisible ? "visible" : "hidden" }}
        ref={backButton}
        onClick={() => {
          window.close();
        }}
      >
        <span className="hidden lg:block bg-black/30 hover:bg-white text-white font-semibold hover:text-black py-2 px-4 border border-gray-100 hover:border-transparent transition">
          一覧へ戻る
        </span>
        <span className="absolute block lg:hidden flex flex-wrap justify-center content-center">
          <i className="text-2xl fa-solid fa-arrow-left p-2.5 bg-black/70 w-[50px] h-[50px] rounded-full"></i>
        </span>
      </div>

      <div className="absolute top-0">
        <Splide
          ref={main}
          className="bg-black/70"
          aria-label="main"
          options={{
            direction: "rtl",
            height: "100dvh",
            heightRatio: 1,
            pagination: false,
            flickPower: 500,
            arrows: true,
            rewind: true,
            start: 0,
            keyboard: "global",
            wheel: true,
            lazyLoad: true,
            autoplay: true, // 自動再生を有効
            interval: 7000, // 自動再生の間隔(ミリ秒)
            breakpoints: {
              640: {
                arrows: false,
              },
            },
          }}
        >
          <Slides contents={props.data} />
        </Splide>
        <div
          className="thumbnail-wrapper"
          style={{ visibility: isInfoVisible ? "visible" : "hidden" }}
        >
          <Splide
            ref={thumbnail}
            // visible は Splideのクラスと競合しているようで、うまく動かない
            aria-label="thumbnail"
            style={{ visibility: isInfoVisible ? "visible" : "hidden" }}
            options={{
              fixedWidth: 60,
              fixedHeight: 60,
              isNavigation: true,
              direction: "rtl",
              height: 60,
              gap: 10,
              paginationKeyboard: true,
              focus: "center",
              lazyLoad: true,
              pagination: false,
              arrows: false,
              cover: false,
              rewind: true,
              dragMinThreshold: {
                mouse: 4,
                touch: 10,
              },
              breakpoints: {
                640: {
                  fixedWidth: 40,
                  fixedHeight: 40,
                  gap: 5,
                },
              },
            }}
          >
            <Slides contents={props.data} />
          </Splide>
        </div>
      </div>
    </>
  );
};
export default ImageSlider;
