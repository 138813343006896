import { SplideSlide } from "@splidejs/react-splide";
import React, { ReactNode } from "react";
import "./Slides.css";
import { gaEventSender } from "../../utils/gaTracking";

interface Content {
  id: number;
  image_url: string | null;
  title: string;

  bg_white?: boolean;
  in_stock?: boolean;
  unit_price?: string;
  description?: string;
  sku?: string;
}

const Slides = (props: {
  contents: Content[];
  children?: ReactNode;
  isProduct?: boolean;
}) => {
  const isProduct = Boolean(props.isProduct);

  const ItemDescription = (props: { content: Content }) => {
    if (isProduct) {
      return (
        <>
          <a
            href={`https://www.manga10.com/product/${props.content.sku}`}
            target="_blank"
          >
            <div
              className="text-white grid grid-cols-2 max-w-2xl relative"
              onClick={() => {
                gaEventSender({
                  action: "navigate_to_manga10",
                  params: {
                    sku: props.content.sku,
                  },
                });
              }}
            >
              <div className="p-2 grid justify-center content-center">
                <div className="max-h-[30dvh]">
                  <img
                    className="h-full object-contain mx-auto"
                    data-id={props.content.id}
                    src={props.content.image_url ?? undefined}
                    alt={props.content.title}
                  />
                  {!props.content.in_stock ? (
                    <span className="absolute inline-block px-2 py-0.5 top-2 left-2 bg-red-600 text-white text-[0.8rem] font-bold rounded -rotate-6">
                      SOLD OUT
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="grid justify-center content-center p-2 lg:p-5">
                <div className="p-2 lg:p-5">
                  <span className="block line-clump-4">
                    {props.content.title}
                  </span>
                  <span className="mt-1 block font-bold">
                    ¥{props.content.unit_price}-
                  </span>
                </div>
              </div>
            </div>
          </a>
        </>
      );
    } else {
      return (
        <>
          <img
            className={`h-screen object-contain mx-auto ${
              props.content.bg_white ? "bg-white" : ""
            }`}
            data-id={props.content.id}
            src={props.content.image_url ?? undefined}
            alt={props.content.title}
            onContextMenu={(e) => e.preventDefault()}
          />
        </>
      );
    }
  };

  if (!props.contents.length) {
    return <></>;
  } else {
    return (
      <>
        {props.contents.map((content) => {
          return (
            <SplideSlide
              key={content.id}
              className="grid justify-center content-center h-full"
            >
              <ItemDescription content={content} />
            </SplideSlide>
          );
        })}
      </>
    );
  }
};
export default Slides;
