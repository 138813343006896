import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import Environment from "../components/meshes/Environment";
import "./Labels.css";
import * as THREE from "three";
import { TextureLoader } from "three";
// @ts-ignore
import vert from "../shaders/vertex.glsl";
// @ts-ignore
import frag from "../shaders/fragment.glsl";

import logo from "../images/manga10logo.png";
import CardMesh from "../components/meshes/CardMesh";
import { loadTextures } from "../utils/textureLoader";
import LabelContext, {
  IndexContextProvider,
} from "../components/store/Labels/label-context";
import ArrowSVG from "../components/common/ArrowSVG";
import _axios from "../utils/axios";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/common/Footer";
import ErrorComponent from "../components/common/ErrorPage";

const Labels = () => {
  const [searchParams] = useSearchParams();
  const [labels, setLabels] = useState<LabelAPI[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [textures, setTextures] = useState<TextureModel[]>([]);
  const [cameraPositionInit, setCameraPositionInit] = useState(false);

  const sk = searchParams.get("sk");

  const fetchData = () => {
    return _axios
      .get(`api/museum/labels/?sk=${sk}`)
      .then(async (res) => {
        setLabels(res.data.results);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!isLoaded) {
      fetchData().then((r) => {
        loadTextures(r?.data.results).then((r) => {
          setTextures(r);
          setIsLoaded(true);
        });
      });
    }
  }, []);

  const Clock = () => {
    useFrame((state, delta) => {
      state.camera.lookAt(new THREE.Vector3(0, 1, 0));

      if (!cameraPositionInit) {
        if (!cameraPositionInit && state.camera.position.z >= 12.0) {
          state.camera.position.z -= 0.5;
        }
        if (
          !cameraPositionInit &&
          state.camera.position.z <= 12.0 &&
          state.camera.position.y >= 1.5
        ) {
          state.camera.position.y -= 0.1;
        }

        if (state.camera.position.y <= 1.5) {
          setCameraPositionInit(true);
        }
      }
    });
    return <></>;
  };

  const cardMesh = useRef<any>();
  const backside = useLoader(TextureLoader, logo);
  backside.flipY = false;

  // ロード前のダミー用テクスチャ
  const preloadTexture: TextureModel = {
    index: 0,
    textureImg: backside,
    ratioY: 1,
    ratioX: 1,
  };

  const Description = () => {
    const { currentIndex, isRotating } = useContext(LabelContext);
    const [index, setIndex] = useState(0);

    useEffect(() => {
      // transition の為に、タイトル部分は再レンダリングさせる
      setIndex(currentIndex);
    }, [currentIndex]);

    if (isLoaded && cameraPositionInit && !isRotating) {
      return (
        <>
          <div className="title-wrapper">
            <span className="title">{labels[index].title}</span>
          </div>
        </>
      );
    }
    return <></>;
  };

  const CardMeshes = () => {
    const { currentIndex, setCurrentIndex, selected, setSelected } =
      useContext(LabelContext);

    useEffect(() => {
      if (selected) {
        setSelected(false);
        const url =`/${labels[currentIndex].slug}/${sk ? `?sk=${sk}` : ''}` ;
        window.location.assign(url);
      }
    }, [selected]);

    if (isLoaded && textures && labels) {
      const texes = textures.sort((a, b) => a.index - b.index);
      return (
        <>
          {texes.map((t, idx) => (
            <CardMesh
              key={idx}
              ref={cardMesh}
              index={t.index}
              indexSize={labels.length - 1}
              frontTex={t}
              backTex={backside}
              changeIndex={setCurrentIndex}
              textureLoaded={isLoaded}
            />
          ))}
        </>
      );
    } else {
      return (
        <>
          {/*ロード完了前はダミーのカードを表示しておく*/}
          <CardMesh
            key={-1}
            ref={cardMesh}
            index={0}
            indexSize={0}
            frontTex={preloadTexture}
            backTex={backside}
            changeIndex={setCurrentIndex}
            textureLoaded={isLoaded}
          />
        </>
      );
    }
  };

  const Indicator = () => {
    const { currentIndex } = useContext(LabelContext);

    if (cameraPositionInit && textures.length && labels.length > 1) {
      return (
        <div className="absolute z-20 w-screen text-white top-[9vh]">
          {[...Array(labels.length)].map((_, idx) => (
            <span
              className={`opacity-25 w-2 h-2 mx-[3px] rounded-full bg-white inline-block ${
                currentIndex === idx ? "opacity-75" : ""
              }`}
              key={idx}
            ></span>
          ))}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const MemoCardMesh = memo(CardMeshes);

  const ArrowButtons = () => {
    // アローボタン。現状未使用

    if (isLoaded && textures && labels) {
      return (
        <>
          <div
            className="md:block hidden opacity-40 arrows-animation absolute z-10 top-[49dvh] right-[20dvw] w-[50px] h-[50px] text-white hover:cursor-pointer"
            onClick={() => {
              cardMesh.current.rotateCardMesh(true);
            }}
          >
            <ArrowSVG width={30} />
          </div>
          <div
            className="md:block hidden opacity-40 arrows-animation -scale-x-100 absolute z-10 top-[49dvh] left-[20dvw] w-[50px] h-[50px] text-white hover:cursor-pointer"
            onClick={() => {
              cardMesh.current.rotateCardMesh(false);
            }}
          >
            <ArrowSVG width={30} />
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <IndexContextProvider>
        { isLoaded && !labels.length ? (
          <ErrorComponent additionalClassName="bg-black/90"/>
        ) : (
            <div
                className="top"
                onWheel={(e) => {
                  cardMesh.current.rotateCardMesh(e.deltaY > 0);
                }}
            >
            <Description />
            <Indicator />
            <Canvas className="main-canvas" camera={{ position: [0, 4, 20] }}>
              <Environment />
              <Clock />

              <MemoCardMesh />
            </Canvas>
          </div>
        )}
      <Footer />
    </IndexContextProvider>
  );
};

export default Labels;
