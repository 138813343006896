import ReactGA from "react-ga4";

export const gaInitializer = () => {
  ReactGA.initialize("G-3FNFDYEW6Y");
};

export const gaEventSender = (args: { action: string; params: any }) => {
  ReactGA.event(args.action, args.params);
};

export const gaLocationSender = (location: any) => {
  ReactGA.send({
    hitType: "pageview",
    // アクセスしたパスとクエリ文字列を送付する
    page: location.pathname + location.search,
  });
};
