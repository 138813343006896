import React from "react";
import { Helmet } from "react-helmet";

const Head = (props: {
  title?: string;
  description?: string;
  image?: string;
}) => {
  const metaArray: Object[] = [{ charSet: "utf-8" }];
  // ページ毎にTwitterCard を変えるのはSPAでは難しい
  // if (props.description) {
  //   metaArray.push({ name: "description", content: props.description });
  //   metaArray.push({ name: "twitter:description", content: props.description });
  // }
  // if (props.image) {
  //   metaArray.push({ name: "twitter:image", content: props.image });
  // }

  return (
    <Helmet
      title={props.title ?? "マンガ展 | Webミュージアム"}
      meta={metaArray}
    />
  );
};
export default Head;
