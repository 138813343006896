import React, { useState } from "react";

const LabelContext = React.createContext({
  currentIndex: 0,
  setCurrentIndex: (idx: number) => {},
  isRotating: false,
  setIsRotating: (bool: boolean) => {},
  startManualRotate: false,
  setStartManualRotate: (bool: boolean) => {},
  rotateDirection: 1,
  setRotateDirection: (num: -1 | 1) => {},
  selected: false,
  setSelected: (bool: boolean) => {},
});

export const IndexContextProvider = (props: { children: React.ReactNode }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRotating, setIsRotating] = useState(false);
  const [startManualRotate, setStartManualRotate] = useState(false);
  const [rotateDirection, setRotateDirection] = useState<1 | -1>(1);
  const [selected, setSelected] = useState(false);

  const _setCurrentIndex = (idx: number) => {
    setCurrentIndex(idx);
  };

  const _setIsRotating = (bool: boolean) => {
    setIsRotating(bool);
  };

  const _setStartManualRotate = (bool: boolean) => {
    setStartManualRotate(bool);
  };

  const _setRotateDirection = (num: -1 | 1) => {
    setRotateDirection(num);
  };

  const _setSelected = (bool: boolean) => {
    setSelected(bool);
  };

  return (
    <LabelContext.Provider
      value={{
        currentIndex,
        setCurrentIndex: _setCurrentIndex,
        isRotating,
        setIsRotating: _setIsRotating,
        startManualRotate,
        setStartManualRotate: _setStartManualRotate,
        rotateDirection,
        setRotateDirection: _setRotateDirection,
        selected,
        setSelected: _setSelected,
      }}
    >
      {props.children}
    </LabelContext.Provider>
  );
};
export default LabelContext;
