import axios from "axios";

const location = window.location.hostname

let baseUrl
if (location === 'dev-museum.manga10.com') {
    baseUrl = 'https://dev-museum.manga10.com/'
}　else if (location ===  'museum.manga10.com') {
    baseUrl = 'https://museum.manga10.com/'
} else {
    baseUrl = 'http://localhost:3022/'
}

const _axios = axios.create({
        baseURL: baseUrl,
    });
export default _axios