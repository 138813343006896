import React, { ReactNode, useEffect, useState } from "react";
import { gaInitializer } from "../../utils/gaTracking";

const GaContext = React.createContext({
  isGaInitialized: false,
  setIsGaInitialized: (bool: boolean) => {},
});

export const GaContextProvider = (props: { children: ReactNode }) => {
  const [isGaInitialized, setIsGaInitialized] = useState(false);
  console.log()

  useEffect(()=>{
      if(!isGaInitialized) {
          // 本番ドメインのみ
          if (window.location.hostname === 'museum.manga10.com') {
            gaInitializer()
          }
          setIsGaInitialized(true)
      }
  },[isGaInitialized])

  const _setIsGaInitialized = (bool: boolean) => {
    setIsGaInitialized(bool);
  };

  return (
    <GaContext.Provider
      value={{
        isGaInitialized: isGaInitialized,
        setIsGaInitialized: _setIsGaInitialized,
      }}
    >
      {props.children}
    </GaContext.Provider>
  );
};

export default GaContext