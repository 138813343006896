import React, {useState} from 'react'

const ProjectContext = React.createContext({
    isVisibleModal: false,
    setIsVisibleModal: (bool: boolean)=>{},
    currentProjectIndex: 0,
    setCurrentProjectIndex: (idx: number)=>{},
    selectedProjectIndex: 0,
    setSelectedProjectIndex: (idx: number)=>{},
    isInit: false,
    setIsInit: (bool: boolean)=>{},
    isLoaded: false,
    setIsLoaded: (bool: boolean)=>{},
    lastMeshPositionY: 0,
    setLastMeshPositionY: (num: number)=>{},
})

export const ModalContextProvider = (props: {children: React.ReactNode})=> {
    const [isVisibleModal, setIsVisibleModal] =useState(false) // 特定のモーダルではなく、何かしらのモーダルが表示されているかどうか
    const [currentProjectIndex, setCurrentProjectIndex] =useState(0)
    const [selectedProjectIndex, setSelectedProjectIndex] =useState(0)
    const [isInit, setIsInit] =useState(false)
    const [isLoaded, setIsLoaded] =useState(false)
    const [lastMeshPositionY, setLastMeshPositionY] =useState(0)

    const _setIsVisibleModal = (bool: boolean)=> {
        setIsVisibleModal(bool)
    }

    const _setCurrentProjectIndex = (idx: number)=> {
        setCurrentProjectIndex(idx)
    }

    const _setSelectedProjectIndex = (idx: number)=> {
        setSelectedProjectIndex(idx)
    }

    const _setIsInit = (bool: boolean)=> {
        setIsInit(bool)
    }

    const _setIsLoaded = (bool: boolean)=> {
        setIsLoaded(bool)
    }

    const _setLastMeshPositionY = (num: number)=> {
        setLastMeshPositionY(num)
    }

    return <ProjectContext.Provider value={{
        isVisibleModal,
        setIsVisibleModal: _setIsVisibleModal,
        currentProjectIndex,
        setCurrentProjectIndex: _setCurrentProjectIndex,
        selectedProjectIndex,
        setSelectedProjectIndex: _setSelectedProjectIndex,
        isInit,
        setIsInit: _setIsInit,
        isLoaded,
        setIsLoaded: _setIsLoaded,
        lastMeshPositionY,
        setLastMeshPositionY: _setLastMeshPositionY,

    }}>
        { props.children }
    </ProjectContext.Provider>
}

export default ProjectContext
