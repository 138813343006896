import * as THREE from "three";
import { MathUtils, Texture } from "three";
import React, {
  RefCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFrame } from "@react-three/fiber";
import { useSwipeable } from "react-swipeable";
import LabelContext from "../store/Labels/label-context";

const CardMesh = React.forwardRef(
  (
    props: {
      index: number;
      indexSize: number;
      frontTex: TextureModel;
      backTex: Texture;
      changeIndex: any;
      textureLoaded: boolean;
    },
    _ref
  ) => {
    const [cardRatioX, setCardRatioX] = useState(2.8);
    const [cardRatioY, setCardRatioY] = useState(5);
    const [cardShapeState, setCardShapeState] = useState(null as any); // Todo: ⬅ ??

    const [startAutoRotate, setStartAutoRotate] = useState(false);
    const [lastRotated, setLastRotated] = useState(4);
    const [isInitialized, setIsInitialized] = useState(false);
    const [canWheel, setCanWheel] = useState(true);

    const [rotationDegreeY, setRotationDegreeY] = useState(Math.PI); // ラジアン π/2 の方

    const yRotationSpeed = 500;
    const side = 10;
    const autoRotatePeriod = 4; // (秒)

    useImperativeHandle(_ref, () => ({
      rotateCardMesh: (direction: boolean = true) => {
        // wheelイベントのトリガーが大量に流れてくる為、制御
        if (canWheel) {
          cardRotate(direction);
          setTimeout(() => {
            setCanWheel(true);
          }, 1200);
        }
        setCanWheel(false);
      },
    }));

    const {
      currentIndex,
      setCurrentIndex,
      isRotating,
      setIsRotating,
      setStartManualRotate,
      rotateDirection,
      setRotateDirection,
      setSelected,
    } = useContext(LabelContext);

    useEffect(() => {}, [currentIndex]);

    useEffect(() => {
      setTimeout(() => {
        setIsInitialized(true);
      }, 600); // ちらつき防止策
    }, [props.textureLoaded]);

    const card: any = useRef();
    const cardMaterial: any = useRef();

    useMemo(() => {
      // 縦横比が16:9
      const width = 2.8;
      const height = 5;
      const roundSlope = 0.5; // 角丸1くらいが丁度

      setCardRatioX(width);
      setCardRatioY(height);

      const startPoint = { x: -width, y: -height + roundSlope };

      const cardShape = new THREE.Shape();
      cardShape.moveTo(startPoint.x, startPoint.y);
      cardShape.quadraticCurveTo(
        startPoint.x,
        startPoint.y - roundSlope,
        startPoint.x + roundSlope,
        startPoint.y - roundSlope
      );
      cardShape.lineTo(width - roundSlope, startPoint.y - roundSlope);
      cardShape.quadraticCurveTo(
        width,
        startPoint.y - roundSlope,
        width,
        startPoint.y
      );
      cardShape.lineTo(width, height - roundSlope);
      cardShape.quadraticCurveTo(width, height, width - roundSlope, height);
      cardShape.lineTo(startPoint.x + roundSlope, height);
      cardShape.quadraticCurveTo(
        startPoint.x,
        height,
        startPoint.x,
        height - roundSlope
      );
      cardShape.moveTo(startPoint.x, startPoint.y);
      setCardShapeState(cardShape);
    }, []);

    const cardRotate = (direction: boolean = true, auto: boolean = false) => {
      const speed = yRotationSpeed;
      if (!auto) {
        setStartManualRotate(true);
      }
      if (auto && isRotating) {
        // Todo：auto の分岐をシンプルにできそう
        //回ってる間は、オート回転しない
      } else {
        setIsRotating(true);
        setStartAutoRotate(true); // ToDo: isRotating との棲み分けは？
        setRotateDirection(direction ? 1 : -1); // 回転用にstate にも渡す
        setTimeout(() => {
          setStartAutoRotate(false);
          setIsRotating(false);
        }, speed);
        setTimeout(() => setStartManualRotate(false), speed * 20);

        setTimeout(() => {
          const dir = direction ? 1 : -1; // setTimeout の中ではタイムラグがある為 state の値を使わない
          if (currentIndex + dir > props.indexSize) {
            setCurrentIndex(0);
            props.changeIndex(0);
          } else if (currentIndex + dir < 0) {
            setCurrentIndex(props.indexSize);
            props.changeIndex(props.indexSize);
          } else {
            setCurrentIndex(currentIndex + dir);
            props.changeIndex(currentIndex + dir);
          }
        }, speed / 2);
      }
    };

    useFrame((state, delta, clock) => {
      if (state.clock.elapsedTime < 1.2) {
        // レンダリング完了するまで何もしない
      } else {
        if (state.clock.elapsedTime - lastRotated > autoRotatePeriod) {
          setStartAutoRotate(true);
          cardRotate(true, true);
        }

        if (isRotating && isInitialized) {
          setLastRotated(state.clock.elapsedTime);
          card.current.rotation.y += delta * 10 * rotateDirection;
          setRotationDegreeY(Math.sin(Math.PI * card.current.rotation.y));
        } else if (Math.abs(MathUtils.radToDeg(rotationDegreeY)) <= 3) {
          // 3°以下なら0にする
          card.current.rotation.y = 0;
        } else {
          card.current.rotation.y += delta * 5 * rotateDirection;
        }
        setRotationDegreeY(Math.sin(card.current.rotation.y)); // Todo:ここ直したほうがよい

      }
    });

    // タッチ操作制御
    const { ref } = useSwipeable({
      onSwipedRight: () => cardRotate(true),
      onSwipedLeft: () => cardRotate(false),
    }) as { ref: RefCallback<Document> };

    useEffect(() => {
      ref(document);
      return () => ref(document);
    });

    // Todo: 何回もレンダリングするといけるよう → 直ったっぽい？

    return (
      <>
        <mesh
          key={props.index}
          position={[0, 1.5, 0]}
          rotation={[0, Math.PI, MathUtils.degToRad(3)]} // rotationY はStateで管理している
          ref={card}
          onClick={() => {
            setSelected(true);
          }}
          visible={props.index === currentIndex}
          onPointerOver={() => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={() => {
            document.body.style.cursor = "default";
          }}
        >
          <shapeGeometry args={[cardShapeState]} />

          <shaderMaterial
            ref={cardMaterial}
            vertexShader={vertDefinition}
            fragmentShader={fragDefinition}
            side={THREE.DoubleSide}
            uniforms={{
              uFrontImg: { value: props.frontTex.textureImg },
              uBackImg: { value: props.backTex },
              uCardRatioX: { value: cardRatioX },
              uCardRatioY: { value: cardRatioY },
              uImgRatioX: { value: props.frontTex.ratioX },
              uImgRatioY: { value: props.frontTex.ratioY },
            }}
          />
        </mesh>
      </>
    );
  }
);

const vertDefinition = `
uniform float uCardRatioX;
uniform float uCardRatioY;
uniform float uImgRatioX;
uniform float uImgRatioY;
varying vec2 v_uv;
varying vec2 v_uvBack;

void main() {
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;
    gl_Position = projectionPosition;
    v_uv = uv;
    
    // 画像比率を考慮する
    v_uv /= vec2(uImgRatioX, uImgRatioY);
    // 長辺に合わせて拡大する
    v_uv /= vec2(uCardRatioY, uCardRatioY);
    // 同じ比率で拡大
    v_uv *= vec2(0.5, 0.5);
    // 位置を合わせる
    v_uv += vec2(0.5, 0.5);
    
    vec2 uvBack = uv;
    uvBack.y = -uv.y;
    uvBack += vec2(2, 2);
    uvBack *= vec2(0.25, 0.25);
    v_uvBack = uvBack;
}
	`;

const fragDefinition = `
uniform sampler2D uFrontImg;
uniform sampler2D uBackImg;
uniform vec2 uUvs;
varying vec2 v_uv;
varying vec2 v_uvBack;

void main() {
    float alpha = gl_FrontFacing ? 0.5 : 0.5;
    vec4 texel = gl_FrontFacing ? texture2D(uFrontImg, v_uv) : texture2D( uBackImg, v_uvBack );
    gl_FragColor = vec4(texel.rgb, alpha);
}
	`;

export default CardMesh;
