import React from "react";

const ErrorComponent = (props: {
  additionalClassName?: string,
  errorCode?: string,
  errorMessage?: string,
}) => {
  return (
    <div
      className={
      `${props.additionalClassName }
       grid text-4xl text-white absolute top-0 z-10 w-screen h-screen text-center content-center`}
    >
      { props.errorCode ?? 404 }
      <span className="text-xl pt-1">
        { props.errorMessage ?? "URLをご確認下さい" }
      </span>
    </div>
  );
};
export default ErrorComponent;
