import * as THREE from "three";

interface imageData {
  image_url: string;
  _order: number;
}

/**
 * 画像URLから、テクスチャを取得
 * 表示順序とアスペクト比の情報を持つ TextureModel を返却する
 * */
export const loadTextures = async (data: imageData[]): Promise<TextureModel[]> => {
  const textures: TextureModel[] = [];
  data
    .sort((a, b) => a._order - b._order)
    .forEach((d, index) => {
      const loader = new THREE.TextureLoader();
      loader.loadAsync(d.image_url).then((tex) => {
        // 長辺を1とする
        const rect = {
          width: { act: tex.image.width, ratio: 1.0 },
          height: { act: tex.image.height, ratio: 1.0 },
        };
        const smaller = Math.min(rect.width.act, rect.height.act);
        if (smaller === tex.image.width) {
          rect.width.ratio = rect.width.act / rect.height.act;
        } else {
          rect.height.ratio = rect.height.act / rect.width.act;
        }
        textures.push({
          index: index,
          textureImg: tex,
          ratioX: rect.width.ratio,
          ratioY: rect.height.ratio,
        });
      });
    });
  return textures;
};
